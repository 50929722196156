<template>
  <div id="map" class="map" ref="mapElement"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
    props: ["coordinates"],
    created(){
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
    },
    data: () => ({
        map: null
    }),
    mounted() {
        this.map = new mapboxgl.Map({
            container: this.$refs["mapElement"], // container ID
            style: 'mapbox://styles/willbicks/ckvwpiugk0nkw15pf19klp3lw', // style URL
            center: this.coordinates, // starting position [lng, lat]
            zoom: 14 // starting zoom
        });

        new mapboxgl.Marker()
            .setLngLat(this.coordinates)
            .addTo(this.map);
    },
}
</script>

<style lang="scss" scoped>
@import '../../node_modules/mapbox-gl/dist/mapbox-gl.css';

.map{
    width: 100%;
    height: 600px;
}
</style>