<template>
    <apexchart type="area" height="450" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        'apexchart': VueApexCharts
    },
    props: {
        binnedStats: {
            type: Array,
            default: null
        }
    },
    data: () => ({
        chartOptions: {
            chart: {
                toolbar:{
                    show: false
                },
                background: '#00000000'
            },
            theme: {
                mode: 'dark'
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    format: 'HH:mm'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'yyyy-MM-dd HH:mm'
                }
            }
        }
    }),
    computed: {
        series: function() {
            return  [{
                name: "Individuals Observed",
                data: this.binnedStats.map(d => ({ x: d.start.getTime(), y: d.count }))
            }]
        }
    }
}
</script>

<style>

</style>