<template>
    <v-container>
        <p class="text-h4 text-md-h3">{{ tallySession.name }}</p>
        <p class="text-h5">Performed by: {{ tallySession.owner.name }}</p>

        <v-row>
            <v-col>
                <v-card class="report-number-card">
                    <v-card-title class="justify-center">Total Individuals Observed</v-card-title>
                    <v-card-subtitle>{{tallySession.species}}</v-card-subtitle>
                    <p class="big-number">{{tallySession.getTotal().toLocaleString()}}</p>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="report-number-card">
                    <v-card-title class="justify-center">Average Velocity</v-card-title>
                    <v-card-subtitle>Individuals / minute</v-card-subtitle>
                    <p class="big-number">{{Math.round(tallySession.getVelocity()).toLocaleString()}}</p>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card class="text-center">
                    <v-card-text>
                        <bar-graph :binned-stats="binnedStats" />

                        <div class="text-h4">
                            Indiviuals Observed over Time
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-5" v-if="tallySession.location.type">
            <v-col>
                <v-card>
                    <v-card-title>Observed at:</v-card-title>
                    <v-card-subtitle>Lat: {{tallySession.location.coordinates[1]}}, Long: {{tallySession.location.coordinates[0]}}</v-card-subtitle>
                    
                    <map-view :coordinates="tallySession.location.coordinates" />

                </v-card>
            </v-col>
        </v-row>

        <p class="text-h5" v-if="tallySession.species">Species of interest: {{tallySession.species}}</p>       
    </v-container>
</template>

<script>
import TallySession from '@/models/TallySession'
import ApexBarGraph from '@/components/TallyTool/ApexBarGraph.vue'
import MapView from '@/components/Map.vue'


export default {
    components: {
        'bar-graph': ApexBarGraph,
        MapView
    },
    data: () => ({
        tallySession: null
    }),
    created() {
        this.tallySession = new TallySession;
        this.tallySession.get(this.$route.params.id)
    },
    computed: {
        binnedStats: function(){
            return this.tallySession.getBinnedStats();
        },
    }
}

</script>

<style lang="scss" scoped>

.report-number-card {
    text-align: center !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.big-number{
    font-size: 5rem;
    line-height: 1;
    margin-top: auto;
}
</style>